import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import SignupForm, { IFormValues } from 'views/auth/components/SignupForm'
import useAuth from 'hooks/auth'
import { Link } from 'react-router-dom'
import { SIGN_IN } from 'routes'
import useApplicationStore from 'hooks/application'
import { APIError } from 'helpers/api'

interface AuthProps extends ModalProps {
  duplicateDocumentId: string
  text: string
}

const AuthModal: React.FC<AuthProps> = ({ duplicateDocumentId, text, id, open, setOpen }) => {
  const { register } = useAuth()
  const { setSnackbarMessage } = useApplicationStore()
  const [state, setState] = useState(1)

  const handleSubmitSignup = async (values: IFormValues) => {
    try {
      const payload = {
        name: values.name,
        email: values.email,
        password: values.password1,
        template: duplicateDocumentId
      }
      await register(payload)
      setState(2)
    } catch (e) {
      if (e instanceof APIError) {
        setSnackbarMessage({
          message: e.message,
          status: 'error',
          duration: 30000,
          action: (
            <a
              style={{
                backgroundColor: 'white',
                border: '1px solid white',
                padding: '5px',
                borderRadius: '2px',
                marginLeft: '10px'
              }}
              href={`/signin`}
            >
              Return To Sign In
            </a>
          )
        })
      }

      return
    }

    setState(2)
  }

  const VerificationSent = (
    <React.Fragment>
      <p className="mt-8 mb-8">
        A verification email has been sent to your email address. Please click on the link in the email to verify your
        account.
      </p>
    </React.Fragment>
  )

  const UserDetails = <SignupForm onSubmit={handleSubmitSignup} template={duplicateDocumentId} />

  return (
    <Modal id={id} open={open} setOpen={setOpen} width="small">
      <div className="flex flex-column items-center" style={{ gap: '20px' }}>
        <div className="font-bold text-xl flex flex-row items-center justify-center">
          Welcome to{' '}
          <img
            src="/assets/images/logo.png"
            alt="logo"
            style={{
              marginLeft: '10px',
              width: '100px',
              height: '20px'
            }}
          />
        </div>
        {state === 1 && <div>{text}</div>}
        {state === 1 && UserDetails}
        {state === 2 && VerificationSent}
      </div>
    </Modal>
  )
}

export default AuthModal
