import useProject from 'hooks/project'
import React, { useEffect } from 'react'
import { history } from 'helpers/history'
import { PROJECT_BASE } from 'routes'
import { getQueryParameter } from 'helpers/utils'

const Project: React.FC = () => {
  const { project } = useProject()

  useEffect(() => {
    const openSearch = getQueryParameter('openSearch')

    if (project.publicId !== '') {
      if (project.defaultProcessId) {
        const newUrl = PROJECT_BASE + project.publicId + '/process/' + project.defaultProcessId

        if (openSearch && openSearch === 'true') {
          history.push(newUrl + '?openSearch=true')
        } else {
          history.push(newUrl)
        }
      } else {
        const newUrl = PROJECT_BASE + project.publicId + '/settings'
        if (openSearch && openSearch === 'true') {
          history.push(newUrl + '?openSearch=true')
        } else {
          history.push(newUrl)
        }
      }
    }
  }, [project.publicId])

  return (
    <div>
      <div></div>
    </div>
  )
}

export default Project
