import React from 'react'
import { useApplicationStore } from 'hooks/application'
import constants from 'style/constants.module.scss'
import Button from 'components/button'

const Powered: React.FC = () => {
  const { sidebarWidth } = useApplicationStore()

  return (
    <div
      className="flex items-center justify-center w-fit-content sm-w-200px overflow-x-auto"
      style={{
        position: 'fixed',
        bottom: 0,
        left: `${sidebarWidth}px`,
        zIndex: 4,
        padding: '15px 20px',
        backgroundColor: 'white',
        fontSize: '90%',
        borderBottom: 'none',
        minWidth: `calc(100vw - ${sidebarWidth}px)`,
        borderTop: `2px solid ${constants.grey}`
      }}
    >
      <b>Powered by</b>
      <a className="flex items-center justify-center" href="https://morta.io" target="_blank" rel="noopener noreferrer">
        <img
          src="/assets/images/logo.png"
          alt="logo"
          style={{
            marginLeft: '10px',
            width: '100px',
            height: '20px'
          }}
        />
      </a>
    </div>
  )
}

export default Powered
