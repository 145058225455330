import React, { useState, useEffect } from 'react'
import { IFolder, IProject, ITableWithCells, ITag, IVariableMap } from 'types'
import { useLocation } from 'react-router-dom'
import api from 'helpers/api'
import { INITIAL_PROJECT_INFORMATION, ProjectRoles } from 'app-constants'
import { history } from 'helpers/history'
import { setRedirectTo } from 'helpers/redirect'
import useAuth from './auth'

const _useProjectProvider = () => {
  const { user } = useAuth()

  const [gettingData, setGettingData] = useState<boolean>(false)
  const [project, updateProject] = useState<IProject>(INITIAL_PROJECT_INFORMATION)
  const [tags, updateTags] = useState<ITag[]>([])
  const [variables, setVariables] = useState<IVariableMap>()
  const [search, setSearch] = useState<{
    open: boolean
    processPublicId?: string
    processName?: string
  }>({
    open: false,
    processPublicId: undefined,
    processName: undefined
  })
  const location = useLocation()

  const openSearch = (processPublicId?: string, processName?: string) => {
    setSearch({
      open: true,
      processPublicId,
      processName
    })
  }

  const closeSearch = () => {
    setSearch({
      open: false,
      processPublicId: undefined,
      processName: undefined
    })
  }

  useEffect(() => {
    const path = location.pathname
    const projectId = path.split('project/')[1]?.split('/')[0]

    if (projectId) {
      if (projectId !== project.publicId && !gettingData) {
        setGettingData(true)
        api({
          method: 'GET',
          endpoint: `/project/${projectId}?excludeMembers=true`
        })
          .then((response) => {
            if (response && response.data) {
              const projectData = response.data as IProject
              updateProject({ ...projectData, loading: false })
            }
            return api.getUserProjectTags(projectId)
          })
          .then((response) => {
            if (response && response.data) {
              const tagsData = response.data as ITag[]
              updateTags(tagsData)
            }
            return api.getProjectVariables(projectId)
          })
          .then((response) => {
            if (response.data) {
              const variables: IVariableMap = {}

              for (let i = 0; i < response.data.length; i++) {
                const table: ITableWithCells = response.data[i]
                const cells = table.cells
                for (let j = 0; j < cells.length; j++) {
                  const cell = cells[j]
                  variables[cell.id] = {
                    label: cell.value ? cell.value : '',
                    group: `${table.name} - ${cell.column.name}`,
                    value: cell.id
                  }
                }
              }

              setVariables(variables)
            }

            setGettingData(false)
          })
          .catch(() => {
            if (!user) {
              setRedirectTo(location.pathname)
            }

            history.push(`/nopermission?project=${projectId}&type=project&id=${projectId}`)
            setGettingData(false)
          })
      }
    } else {
      updateProject(INITIAL_PROJECT_INFORMATION)
    }
  }, [location])

  const getFolderResources = (typeId: string) => {
    api.getProjectResources(project.publicId, { typeId: typeId ? typeId : '' }).then((response) => {
      updateProject({
        ...project,
        folders: project.folders.map((folder: IFolder) =>
          folder.id === typeId ? { ...folder, resources: response.data } : folder
        )
      })
    })
  }

  const projectRole = project.role
  const projectAdmin = projectRole === ProjectRoles.ADMIN || projectRole === ProjectRoles.OWNER
  const projectOwner = projectRole === ProjectRoles.OWNER

  return {
    project,
    updateProject,
    getFolderResources,
    projectRole,
    projectAdmin,
    projectOwner,
    search,
    openSearch,
    closeSearch,
    tags,
    variables
  }
}

type ProjectContextType = ReturnType<typeof _useProjectProvider>

const ProjectContext = React.createContext<ProjectContextType | undefined>(undefined)

export const ProjectProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const value = _useProjectProvider()
  return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
}

export const useProject = () => {
  const context = React.useContext(ProjectContext)

  if (context === undefined) {
    throw new Error('useProject can only be used within a Project context')
  }

  return context
}

export default useProject
