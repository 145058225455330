import React, { useEffect, useState } from 'react'
import { getQueryParameter } from 'helpers/utils'
import api, { APIError } from 'helpers/api'
import { useAuth } from 'hooks/auth'
import { HOME } from 'routes'
import Button from 'components/button'
import { history } from 'helpers/history'

const ConfirmEmailVerificationCode: React.FC = () => {
  const code = getQueryParameter('token')
  const [error, setError] = useState<string | null>(null)
  const { updateUser } = useAuth()

  document.title = 'Morta | Verify Email'

  useEffect(() => {
    if (!code) return

    const confirmAccount = async () => {
      try {
        const response = await api.verifyAccount(code)
        updateUser(response.data)
        const project = getQueryParameter('project')
        const redirect = project ? `/project/${project}` : HOME
        history.push(redirect)
      } catch (err) {
        if (err instanceof APIError) {
          console.error(err.message)
        }
        setError(
          'There was a problem confirming your account. Verification emails are valid for 24 hours. You can try sending another verification email.'
        )
      }
    }

    confirmAccount()
  }, [code])

  if (!code) {
    return <p>Please check you have typed in the url correctly. We could not find your verification code.</p>
  }

  return (
    <div className="bg-really-light-blue flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-8">
      <div
        className="h-fit flex flex-column bg-white border-1px border-solid border-black p-8 heavy-shadow rounded text-primary"
        style={{ width: '550px' }}
      >
        {error ? (
          <div>
            <p>{error}</p>
            <br />
            <Button to={HOME}>Go back</Button>
          </div>
        ) : (
          <p>Verifying your account, please wait...</p>
        )}
      </div>
    </div>
  )
}

export default ConfirmEmailVerificationCode
