import React, { useState, useEffect } from 'react'
import Button from 'components/button'
import CreateProjectModal from 'views/home/components/createProjectModal'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { IProcessObject, IProject } from 'types'
import useAuth from 'hooks/auth'
import { MFA, Plus, FilledStar, NonFilledStar } from 'components/icons'
import { PROJECT } from 'app-constants'
import { capitaliseFirstLetter } from 'helpers/utils'
import constants from 'style/constants.module.scss'
import Editor from 'components/editor'
import HomeTemplateModal from 'views/home/components/template'
import { INTEGRATIONS } from 'routes'

const Home: React.FC = () => {
  const [filter, setFilter] = useState<any>({
    active: false,
    search: '',
    ascending: true
  })

  const [projectsLoading, setProjectsLoading] = useState<boolean>(false)
  const [projects, setProjects] = useState<IProject[]>([])
  const [includeDeleted, setIncludeDeleted] = useState<boolean>(false)
  const [templates, setTemplates] = useState<Partial<IProcessObject>[]>()
  const [duplicateModal, setDuplicateModal] = useState<{ open: boolean; publicId: string; name: string }>({
    open: false,
    publicId: '',
    name: ''
  })

  const { setTemplatesModal, displayErrorMessage } = useApplicationStore()
  const { user, updateUser, logout } = useAuth()

  useEffect(() => {
    api
      .getTemplates()
      .then((response) => {
        if (response && response.data) {
          setTemplates(response.data)
        } else {
          displayErrorMessage('Failed to get templates')
        }
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }, [])

  useEffect(() => {
    const getFile = async (url: string, name: string) => {
      const signedFileTokenResponse = await api.signUrl(url)
      const downloadUrl = signedFileTokenResponse.data.url

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = downloadUrl
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      const newUrl = `${window.location.origin}${window.location.pathname}`
      window.history.replaceState({ path: newUrl }, '', newUrl)
    }

    const getTableFile = async (tableId: string, columnId: string, filename: string) => {
      api
        .getFile({
          tableId: tableId,
          columnId: columnId,
          filename: filename
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    }

    const params = new URLSearchParams(window.location.search)
    const fileUrl = params.get('fileUrl')
    const fileName = params.get('fileName')
    const tableId = params.get('tableId')
    const columnId = params.get('columnId')
    if (fileUrl && fileName) {
      try {
        getFile(fileUrl, fileName)
      } catch (e) {
        displayErrorMessage(e)
      }
    } else if (fileName && tableId && columnId) {
      try {
        getTableFile(tableId, columnId, fileName)
      } catch (e) {
        displayErrorMessage(e)
      }
    }
  }, [window.location.search])

  useEffect(() => {
    document.title = 'Morta | Home'
    if (user) {
      setProjectsLoading(true)
      api
        .getProjects()
        .then((response) => {
          if (response && response.data) {
            setProjects(response.data)
          }
          setProjectsLoading(false)
        })
        .catch(async (error) => {
          setProjectsLoading(false)
          if (error.rawCode === 401) {
            await logout()
          } else {
            displayErrorMessage(error)
          }
        })
    }
  }, [user])

  const [modalOpen, setModalOpen] = React.useState(false)

  function favouriteProject(projectId: string) {
    api({
      method: 'PUT',
      endpoint: '/user/projects/' + projectId + '/favourite'
    })
      .then((response) => {
        if (user && response && response.data) {
          updateUser({ ...user, favourites: response.data })
        }
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }

  function sortProjects(a: IProject, b: IProject) {
    const aFavourite =
      user && user.favourites && user.favourites.findIndex((favourite) => favourite.publicId === a.publicId) !== -1
        ? 1
        : 0
    const bFavourite =
      user && user.favourites && user.favourites.findIndex((favourite) => favourite.publicId === b.publicId) !== -1
        ? 1
        : 0

    const projectAName = a.name.toLowerCase()
    const projectBName = b.name.toLowerCase()

    return (
      bFavourite - aFavourite ||
      (projectAName > projectBName
        ? filter.ascending
          ? 1
          : -1
        : projectAName > projectBName
        ? filter.ascending
          ? -1
          : 1
        : 0)
    )
  }

  const communityProjectId = 'c635af4b-5e32-4d42-98a1-994108710141'

  const pinnedHubs = [
    {
      name: 'Morta Community',
      abbreviation: 'Co',
      publicId: 'c635af4b-5e32-4d42-98a1-994108710141',
      backgroundColor: '#3d80fc'
    },
    {
      name: 'UK BIM Framework Guidance',
      abbreviation: 'UK',
      publicId: 'a690e3a3-aa56-4a14-bb59-887f9c743b31',
      backgroundColor: '#084c9c'
    }
  ]

  const filteredSortedHubs = projects
    ? projects
        .sort((a: IProject, b: IProject) => sortProjects(a, b))
        .filter((project: IProject) => project.publicId !== communityProjectId)
        .filter((project: IProject) => project.name.toLowerCase().includes(filter.search.toLowerCase()))
    : []

  const templatesToDisplay = [
    '6f481bb7-1427-4c44-bac3-8e4d734e216a',
    'f7945829-a8bf-4849-ba88-c08e3025656d',
    'a0f2daab-af8a-4efd-8be1-c274d17842f7',
    '0e49fc38-65b8-4325-a4ff-9e4f1da191dc',
    '2b0b0acd-94b9-4751-b74b-c6526676dac3',
    'ea1c1751-2198-46df-a335-24620960d3f6',
    '705a2e8d-211e-46be-ab2f-3f3aa431d0ef'
  ]

  const filteredTemplates = templates
    ? templates.filter((template) => template.publicId && templatesToDisplay.includes(template.publicId))
    : undefined

  const hideTemplates = user && user.email.includes('@kier.co.uk') ? true : false

  return (
    <div className="select-none flex flex-column w-full bg-really-light-grey" style={{ padding: '30px' }}>
      {!hideTemplates && (
        <div className="flex items-center" style={{ marginBottom: '20px' }}>
          <div className="font-bold text-xl" style={{ marginRight: 'auto' }}>
            Templates For You
          </div>
          <Button className="mobile-display-none" internalType="outline" to={INTEGRATIONS}>
            Go To Integrations
          </Button>
          <Button
            className="mobile-display-none"
            style={{ marginLeft: '15px' }}
            internalType="outline"
            onClick={() => setTemplatesModal(true)}
          >
            See All Templates
          </Button>
        </div>
      )}

      {!hideTemplates && (
        <div className="grid grid-cols-6 sm-grid-cols-1 gap-16px">
          {!filteredTemplates && <span className="skeleton-box" style={{ height: '210px' }} />}
          {filteredTemplates && filteredTemplates.length === 0 && (
            <div className="text-primary w-full">No templates found</div>
          )}
          {filteredTemplates &&
            filteredTemplates.length > 0 &&
            filteredTemplates.map(function (template: Partial<IProcessObject>, templateIndex: number) {
              return (
                <div
                  key={templateIndex}
                  className="items-center rounded border-1px border-solid border-black box-border hover-heavy-shadow transition-all cursor-pointer"
                  tabIndex={0}
                  onClick={() => {
                    if (template && template.publicId && template.name) {
                      setDuplicateModal({ open: true, publicId: template.publicId, name: template.name })
                    }
                  }}
                >
                  <div
                    className="flex items-center font-bold box-border border-b-1px border-solid border-dark-grey"
                    style={{ padding: '10px', borderRadius: '0.25rem 0.25rem 0 0' }}
                  >
                    {template.name}
                  </div>
                  <div className="overflow-y-auto text-sm" style={{ height: '150px' }}>
                    <Editor
                      databaseDoc={template.description}
                      readOnly={true}
                      editorId={templateIndex.toString()}
                      border={false}
                    />
                  </div>
                </div>
              )
            })}
        </div>
      )}

      <div className="flex items-center" style={{ marginTop: !hideTemplates ? '60px' : '20px', marginBottom: '20px' }}>
        <div className="font-bold text-xl">Community {capitaliseFirstLetter(PROJECT)}s</div>
      </div>

      <div className="grid grid-cols-3 sm-grid-cols-1 gap-16px">
        {pinnedHubs.map((hub, index) => (
          <a
            key={index}
            className="flex items-center p-2 rounded cursor-pointer transition-all border-1px border-solid border-black box-border hover-heavy-shadow"
            style={{ background: constants.reallyLightBlue }}
            href={`/project/${hub.publicId}`}
          >
            <div
              className="text-primary flex items-center justify-center rounded m-10px text-xl text-white"
              style={{
                minWidth: '40px',
                width: '40px',
                height: '40px',
                backgroundColor: hub.backgroundColor
              }}
            >
              {hub.abbreviation}
            </div>
            <div className="text-primary font-normal px-10px truncate">{hub.name}</div>
          </a>
        ))}
      </div>

      <div className="flex items-center" style={{ marginTop: '60px', marginBottom: '20px' }}>
        <div className="text-xl font-bold">My {capitaliseFirstLetter(PROJECT)}s</div>
        <input
          className="ml-auto mobile-display-none"
          placeholder={`Search for a ${PROJECT}...`}
          style={{ width: '300px', marginRight: '7px', height: '36px' }}
          onChange={(event) =>
            setFilter({
              ...filter,
              search: event.target.value
            })
          }
        />

        <Button
          className="mobile-display-none"
          internalType="outline"
          style={{ minWidth: '120px', marginRight: '7px' }}
          onClick={() =>
            setFilter({
              ...filter,
              sort: 'name',
              ascending: !filter.ascending
            })
          }
        >
          Sort {filter.ascending ? 'A → Z' : 'Z → A'}
        </Button>

        <Button
          className="mobile-display-none"
          internalType="outline"
          style={{ minWidth: '120px', marginRight: '5px' }}
          onClick={() => setIncludeDeleted(!includeDeleted)}
        >
          {includeDeleted ? 'Hide Archived' : 'Show Archived'}
        </Button>

        <Button
          className="mobile-display-none"
          style={{ width: '175px', marginLeft: '5px' }}
          internalType="accept"
          onClick={() => setModalOpen(true)}
        >
          <Plus style={{ marginRight: '5px' }} />
          Create New {capitaliseFirstLetter(PROJECT)}
        </Button>
      </div>

      <div className="grid grid-cols-4 sm-grid-cols-1 gap-16px">
        {projectsLoading && <span className="skeleton-box" style={{ height: '93px' }} />}
        {filteredSortedHubs.length === 0 && !projectsLoading && (
          <div className="text-primary w-full">No {PROJECT}s found</div>
        )}
        {!projectsLoading &&
          filteredSortedHubs.length > 0 &&
          filteredSortedHubs.map(function (project: IProject, projectNumber: number) {
            if (!project.isDeleted || (includeDeleted && project.isDeleted))
              return (
                <a
                  key={projectNumber}
                  className={`flex items-center p-2 rounded cursor-pointer transition-all border-1px border-solid border-black box-border hover-heavy-shadow ${
                    project.isDeleted ? 'bg-grey' : 'bg-white'
                  }`}
                  href={`/project/${project.publicId}`}
                  data-cy={project.name}
                >
                  <div
                    className="text-primary flex items-center justify-center rounded m-10px text-xl text-white"
                    style={{
                      minWidth: '40px',
                      width: '40px',
                      height: '40px',
                      backgroundColor: project.primaryColour
                    }}
                  >
                    {project.name.substring(0, 2)}
                  </div>
                  <div className="text-primary font-normal px-10px truncate">{`${project.name}${
                    project.isDeleted ? ' (Archived)' : ''
                  }`}</div>
                  {project.mfaRequired && (
                    <div className="ml-auto flex items-center" style={{ marginRight: '15px' }}>
                      <MFA style={{ width: '25px', height: '25px' }} />
                      <span className="text-primary text-sm">MFA Required</span>
                    </div>
                  )}

                  {user && (
                    <div
                      className={`${!project.mfaRequired && 'ml-auto'} transition-all`}
                      onClick={(event) => {
                        event.preventDefault()
                        favouriteProject(project.publicId)
                      }}
                      title={
                        user &&
                        user.favourites &&
                        user.favourites.map((project: Partial<IProject>) => project.publicId).includes(project.publicId)
                          ? 'Remove from favourites'
                          : 'Add to favourites'
                      }
                    >
                      {user &&
                      user.favourites &&
                      user.favourites
                        .map((project: Partial<IProject>) => project.publicId)
                        .includes(project.publicId) ? (
                        <FilledStar
                          style={{
                            height: '25px',
                            width: '25px'
                          }}
                        />
                      ) : (
                        <NonFilledStar
                          style={{
                            height: '25px',
                            width: '25px'
                          }}
                        />
                      )}
                    </div>
                  )}
                </a>
              )
          })}
      </div>

      {modalOpen && <CreateProjectModal id="create-project-modal" open={modalOpen} setOpen={setModalOpen} />}
      {duplicateModal.open && duplicateModal.publicId && (
        <HomeTemplateModal
          id="duplicate-template-modal"
          open={duplicateModal.open}
          setOpen={(open: boolean) => setDuplicateModal({ ...duplicateModal, open })}
          onClose={() => setDuplicateModal({ publicId: '', name: '', open: false })}
          templateName={duplicateModal.name}
          templateId={duplicateModal.publicId}
        />
      )}
    </div>
  )
}

export default Home
